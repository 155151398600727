import React from 'react'
import WebpImage from '../WebpImage'

import './style.scss'


const OrderConfirmation = ({userData}) => {
  const gift = userData['url'] === ("frames") ? 'frames-thanks.png' : 'GiftGreenWhite.png'

  return (
    <div className="order-confirmation section">
      <h3 className="title">Order <span id="confirmation-number">{ userData.order_number || userData.order_id}</span></h3>
      <p className="confirmation-email mt-3">
        A confirmation email was sent to { userData.email }
      </p>
      <div className="img-wrapper my-3">
        <WebpImage
          fileName={gift}
          alt="Order confirmation gift"
          className="img-fluid gift" />
      </div>
      <div className="confirmation-address">
        <p>
          { userData.firstName } <br />
          { userData.address1 } <br />
          { userData.city }, { userData.province }, { userData.zip } <br />
          United States
        </p>
      </div>
    </div>
  )
}

export default OrderConfirmation
