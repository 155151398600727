import React from "react";
import "./style.scss";

const DynamicButton = (props) => {
  return (
    <div className={`cta-linkout ${props.wrapperClass}`}>
      <a disabled={props.disabled} href={props.href} onClick={props.handleClick} className={`button active ${props.size}-button dynamic-button`} id={props.id} >
        <div className="outer-border">
          <i className={`icon-Button_${props.size.toUpperCase()} ${props.borderColor}`} />
        </div>
        <div className={`background ${props.bgColor}-bg`}>
          <div className={`destination ${props.textColor}`}>{props.copy}</div>
        </div>
      </a>
    </div>
  )
}

export default DynamicButton
