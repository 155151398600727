import React from "react"
import SEO from "../../components/seo"
import Mission from "../../components/Mission"
import Thanks from "../../components/Thanks"
import NewFooter from "../../components/NewFooter"

const ThanksPage = () => (
  <div className="thanks-section">
    <SEO title="Thanks from Hubble!" />
    <div className="mission-wrapper">
      <Mission
        bgColor="lightCyanLimeGreen"
        color="darkCyanLimeGreen"
        title="Thank you for your purchase!"  />
    </div>
    <Thanks />
    <NewFooter />
  </div>
)

export default ThanksPage
