import React, { useState } from 'react'
import DynamicButton from '../DynamicButton'
import './style.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/pro-light-svg-icons";
import axios from 'axios';

const options = [
  {displayValue: "Mailer", internal_value:  "mailer", id: '1' },
  {displayValue: "Friends or family", internal_value:  "friends_or_family", id: '2' },
  {displayValue: "Search engine (eg: Google)", internal_value:  "mailer", id: '4' },
  {displayValue: "Website banner ad", internal_value:  "website_banner_ad", id: '5' },
  {displayValue: "Online Article", internal_value:  "online_article", id: '7' },
  {displayValue: "Facebook", internal_value:  "facebook", id: '8' },
  {displayValue: "Instagram", internal_value:  "instagram", id: '9' },
  {displayValue: "Internet Radio", internal_value:  "other", id: '50' },
  {displayValue: "iHeartRadio", internal_value:  "other", id: '51' },
  {displayValue: "Radio", internal_value:  "radio", id: '10' },
  {displayValue: "Podcast", internal_value:  "podcast", id: '11' },
  {displayValue: "My Optometrist", internal_value:  "my_od", id: '12' },
  {displayValue: "Snapchat", internal_value:  "snapchat", id: '15' },
  {displayValue: "Pinterest", internal_value:  "pinterest", id: '16' },
  {displayValue: "Reddit", internal_value:  "reddit", id: '48' },
  {displayValue: "Television", internal_value:  "television", id: '3' },
  {displayValue: "TikTok", internal_value:  "tiktok", id: '49' },
  {displayValue: "Twitter", internal_value:  "twitter", id: '17' },
  {displayValue: "Don't Remember", internal_value:  "dont_remember", id: '13' },
  {displayValue: "Pandora", internal_value:  "pandora", id: '19' },
  {displayValue: "YouTube", internal_value:  "youtube", id: '20' },
  {displayValue: "Other", internal_value:  "other", id: '14' }
]


const purchase_options = [
  {displayValue: "My eye doctor / optometrist", internal_value:  "doctor", id: '1' },
  {displayValue: "Physical retailer (e.g., Costco, Walmart, Lenscrafters)", internal_value:  "physical_retailer", id: '2' },
  {displayValue: "1800 contacts", internal_value:  "1800contacts", id: '3' },
  {displayValue: "Lens.com", internal_value:  "lens.com", id: '4' },
  {displayValue: "Lensdirect", internal_value:  "lensdirect", id: '5' },
  {displayValue: "This is my first time buying contacts", internal_value:  "first_time", id: '6' },
  {displayValue: "Other physical retailer", internal_value:  "other_physical", id: '7' },
  {displayValue: "Other online retailer", internal_value:  "other_online", id: '8' },
]


const Survey = ({order_id,customerEmail}) => {
  const [checkedKey, setCheckedKey] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [surveyStep, setSurveyStep] = useState(1)

  const handleCheck = (option_id) => {
    setCheckedKey(option_id)
  }

  const shuffleArray = (arr) => {
    const newArr = arr.slice()
    for (let i = newArr.length - 1; i > 0; i--) {
        const rand = Math.floor(Math.random() * (i + 1));
        [newArr[i], newArr[rand]] = [newArr[rand], newArr[i]];
    }
    return newArr
  };

  const renderRadioButtons = (options) => (
    options.map((option) => (
      <div key={option['displayValue']} className="survey-answer-wrapper">
        <FontAwesomeIcon
          className="survey-checkbox"
          icon={checkedKey === option['id'] ? faCheck : faCircle}
          onClick={() => handleCheck(option['id'])}
          style={{
            color: checkedKey === option['id'] ? '#00a05f' : '#333',
            width: '16px',
            height: '16px',
          }} />

        <input
          id={option['id']}
          checked={checkedKey === option['id']}
          className="form-check-input survey-answer"
          // key={option['id']}
          onClick={(e) => handleCheck(option['id'])}
          onChange={(e) => handleCheck(option['id'])}
          value={option['displayValue']}
          type="radio" />

        <label
          className="survey-label"
          htmlFor={option['displayValue']}
          // key={option['id']}
          onClick={(e) => handleCheck(option['id'])}
          >
          {option['displayValue']}
        </label>
      </div>
    ))
  )

  const handleAttributionSurveySubmit = (e) => {
    e.preventDefault();
    const survey_data = {
      country: "US",
      order_id: order_id,
      channel: checkedKey,
      customer_email: customerEmail
    }
    // TODO: Add podcast/TV/radio/etc ID for secondary question here
    axios.post(`https://api.hubblecontacts.com/webhooks/attribution_surveys/create.json`, survey_data, { headers: {
      'Access-Control-Allow-Origin' : '*',
      'Access-Control-Allow-Credentials':true,
      'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    }}).then((response) => {
      setSurveyStep(2)
      setCheckedKey('')
      // setFormSubmitted(true)
    })

  }

  const handlePreviousShoppingSurveySubmit = (e) => {
    e.preventDefault();
    const survey_data = {
      country: "US",
      order_id: order_id,
      channel: checkedKey
    }
    // TODO: Add podcast/TV/radio/etc ID for secondary question here
    axios.post(`https://api.hubblecontacts.com/webhooks/previous_shopping_surveys/create.json`, survey_data, { headers: {
      'Access-Control-Allow-Origin' : '*',
      'Access-Control-Allow-Credentials':true,
      'Access-Control-Allow-Methods' : 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    }}).then((response) => {
      setFormSubmitted(true)
    })

  }

  return (
    <div className="survey-section section container text-center">
      {surveyStep === 1 &&
        <>
          <h3>How did you first hear about Hubble?</h3>
          <div className="form-wrapper">
            <form className="form text-left mt-5">
              <div className="row">
                <div className="form-check attribution-survey">
                  {renderRadioButtons(options)}
                </div>
              </div>
              <div className="btn-wrapper my-5 text-center" onClick={(e) => handleAttributionSurveySubmit(e) } >
                <DynamicButton
                  copy="Submit"
                  textColor="darkCyanLimeGreen"
                  size="m"
                  borderColor="darkCyanLimeGreen"
                  />
              </div>
            </form>
          </div>
        </>
      }

      {surveyStep === 2 && formSubmitted === false &&
        <>
          <h3>Before you bought Hubble, from where did you generally purchase contact lenses?</h3>
          <div className="form-wrapper">
            <form className="form text-left mt-5">
              <div className="row">
                <div className="form-check attribution-survey">
                  {renderRadioButtons(purchase_options)}
                </div>
              </div>
              <div className="btn-wrapper my-5 text-center" onClick={(e) => handlePreviousShoppingSurveySubmit(e) } >
                <DynamicButton
                  copy="Submit"
                  textColor="darkCyanLimeGreen"
                  size="m"
                  borderColor="darkCyanLimeGreen"
                  />
              </div>
            </form>
          </div>
        </>
      }


      {formSubmitted  &&
        <div>
          <h3 class="title is-3 souvenir submitted">We appreciate your feedback!</h3>
        </div>
      }

    </div>
  )
}

export default Survey
