export default function roktTrack(data) {
  if (typeof window !== "undefined") {
    if (Object.keys(data).length !== 0) {
      const rokt = window._ROKT || [];
      rokt.push(['firstName', data.name.split(' ')[0]]);
      rokt.push(['lastName', data.name.split(' ')[1]]);
      rokt.push(['email', data.email]);
      rokt.push(['gender', undefined]);
      rokt.push(['mobile', undefined]);
      rokt.push(['address1', data.address1]);
      rokt.push(['city', data.city]);
      rokt.push(['postcode', data.zip]);
      rokt.push(['state', data.province]);
      rokt.push(['country', data.country]);
    }
  }
}
